.headerMainNameTitle {
  color: #333 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-left: 16px !important;
}

.headerText {
  color: #333 !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.headerSubText {
  color: #333 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 14px !important;
}

.headerTextActive {
  color: #333 !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: normal !important;
}
.headerSubTextActive {
  color: #333 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding: 14px !important;
}

.downSvg {
  fill: #333 !important;
}

.downSvgActive {
  fill: #333 !important;
}

.headerText:hover {
  color: #333 !important;
}
.headerText:hover > .downSvg {
  fill: #333 !important;
}
.headerSubText:hover {
  color: #333 !important;
}

.mobileHeader {
  display: none !important;
}

.containerBorder {
  border-bottom: 1px solid #dcdcdc !important;
}

.containerMiniBorder {
  border-bottom: none !important;
}

@media (max-width: 370px) {
  .headerText {
    font-size: 13px !important;
  }
}

@media (max-width: 991px) {
  .headerText {
    color: #333 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .headerSubText {
    color: #333 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .headerTextActive {
    color: #333 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
  }
  .headerSubTextActive {
    color: #333 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .headerLogin {
    display: none !important;
  }

  .mobileHeader {
    display: flex !important;
    flex-direction: column;
  }

  .mobileHeaderIner {
    display: flex !important;
    flex-direction: row !important;
  }

  .containerMiniBorder {
    border-bottom: 1px solid #dcdcdc !important;
  }
  .navbar-brand {
    margin-left: 24px;
  }
}
