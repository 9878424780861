@font-face {
  font-family: "Nanum Myeongjo";
  font-display: fallback;

  src: url("../../assets/font/NanumMyeongjo.otf") format("opentype");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "NanumSquareOTF_ac";
  font-display: fallback;

  src: url("../../assets/font/NanumSquareOTF_acL.otf") format("opentype");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}

.reviewcustomWrap {
  padding: 80px 10px 60px 10px !important;
  background-color: #f3f3f3 !important;
}

.ReviewTitle {
  color: #0b0b0b !important;
  font-family: Pretendard !important;
  // font-size: 34px !important;
  font-size: 50px !important;
  font-style: normal !important;
  // font-weight: 700 !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 60px !important;
}

.reviewCard {
  box-shadow: none !important;
  width: 100% !important;
  margin-bottom: 20px !important;
  aspect-ratio: 1 / 1 !important; /* Ensure 1:1 aspect ratio */
  display: flex !important;
  flex-direction: column !important;
  border-radius: 0px !important;
  cursor: pointer;
}

.reviewCardHeader {
  display: flex !important;
  justify-content: space-between !important;
  padding: 23px 28px !important;
  background-color: #4f4f4f !important;
  flex: 0 1 auto !important; /* Allow header to adjust its height */
}


.reviewCardHeaderStar {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
  margin-right: 12px;
}

.reviewCardBody {
  display: flex !important;
  flex: 1 1 0 !important; /* Ensure body takes the remaining space */
  align-items: end !important;
  // padding: 0px 28px 26px !important;
  padding: 0px 36px !important;
  color: #fff !important;
  background-color: #00000066 !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.reviewCardHeaderName {
  padding: 0 !important;
  color: #ccc !important;
  font-family: Pretendard !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 50px !important; /* 192.308% */
  letter-spacing: -0.26px !important;
  margin-left: 8px;
}


.reviewCardName {
  font-family: Pretendard !important;
  font-size: 40px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 52px !important; /* 121.739% */
  margin-bottom: 2px !important;
  color: #fff !important;
  background: none !important;
  border: none !important;
  resize: none !important;
  white-space: pre-line
}

.reviewCardDesc {
  font-family: Pretendard !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 36px !important; /* 165.385% */
  letter-spacing: -0.78px !important;
  margin-bottom: 5px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 128px; /* Adjust based on line-height */
}
.reviewCardMore {
  font-family: Pretendard !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 43px !important; /* 165.385% */
  letter-spacing: -0.78px !important;
  margin-bottom: 25px !important;
}
.review1Card {
  box-shadow: none !important;
  width: 100% !important;
  margin-bottom: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 0px !important;
  cursor: pointer;
}

.review1CardHeader {
  display: flex !important;
  justify-content: space-between !important;
  padding: 23px 28px !important;
  background-color: #4f4f4f !important;
  flex: 0 1 auto !important; /* Allow header to adjust its height */
}

.review1CardHeaderName {
  padding: 0 !important;
  color: #ccc !important;
  font-family: Pretendard !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 50px !important; /* 192.308% */
  letter-spacing: -0.26px !important;
}

.review1CardHeaderStar {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}

.review1CardBody {
  display: flex !important;
  flex: 1 1 0 !important; /* Ensure body takes the remaining space */
  align-items: end !important;
  padding: 44px 48px 40px !important;
  color: #4f4f4f !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.review1CardName {
  font-family: Pretendard !important;
  font-size: 46px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 56px !important; /* 121.739% */
  margin-bottom: 22px !important;
  color: #4f4f4f !important;
  background: none !important;
  border: none !important;
  resize: none !important;
}

.review1CardDesc {
  color: #4f4f4f !important;
  font-family: Pretendard !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 43px !important; /* 165.385% */
  letter-spacing: -0.88px !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  -webkit-line-clamp: 4 !important; /* Limit text to 4 lines */
  text-overflow: ellipsis !important;
  margin-bottom: 22px !important;
}

.review1CardDate {
  color: #a1a1a1 !important;
  font-family: Pretendard !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 43px !important; /* 165.385% */
}

.partyModalName {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;
  font-family: "Nanum Myeongjo" !important;
  font-size: 78px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: -3.3px !important;
  text-transform: uppercase !important;
  margin-bottom: 38px !important;
  line-height: 77.778px !important;
}

.partyModalHeader {
  color: #fff !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 33.444px !important; /* 111.481% */
  letter-spacing: 0.9px !important;
}
.partyModalreviewerName {
  color: #dccebf !important;
  text-align: center !important;
  font-family: "NanumSquareOTF_ac" !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 38.889px; /* 149.573% */
  letter-spacing: 1.3px;
  margin-bottom: 23px !important;
}
.partyModalDesc {
  color: #dccebf !important;
  font-family: "NanumSquareOTF_ac" !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 200 !important;
  line-height: 42px !important; /* 161.538% */
  letter-spacing: 0.78px !important;
  margin-top: 42px !important;
}
.partyModalBodyWrap {
  padding: "50px 100px" !important;
  display: flex;
  justify-content: center;
}
.partyModalHeaderWrap {
  padding: 100px 50px 70px 50px !important;
  border-radius: 8px !important;
  background: rgba(0, 0, 0, 0.6) !important;
  max-width: 996px !important;
}
.partyClose {
  width: auto !important;
}
.review-container {
  text-align: center !important;
  color: white;
  padding-top: 62px;
  padding-left: 62px!important;
  padding-right: 62px!important;
  padding-bottom: 162px!important;
  border-radius: 40px;
  
  margin: auto;
  font-family: 'Arial', sans-serif;
}



.review-container h1 {
  font-size: 78px;
  margin-bottom: 38px;
  color:#fff;
  font-family: 'NanumMyeongjo';
}

.review-container h2 {
  font-size: 26px;
  color:#DCCEBF;
  margin-bottom: 23px;
  line-height: 42px;
}

.review-stars {
  font-size: 1em;
  color: #DCCEBF;
  margin-bottom: 38px;
}

.review-line{
  width: 100%;
  height: 0.189px;
  background-color: #D7D6D6;
  stroke-width: 0.189px;
  stroke: #D7D6D6;
  
}
.review-content  {
  margin-top: 42px;
  // padding-bottom: 150%;
  
}

.review-content p {
  margin-top: 42px;
  text-align: left !important;
  color: #DCCEBF;
  font-family: Pretendard !important;
  line-height: 42px;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  
}


.review-content p:first-child {
  margin-top: 0;
}

.review-content p:last-child {
  margin-bottom: 0;
}
.review-image-box img{
  width: 100%;   /* 상위 div의 너비에 맞춰서 이미지 크기 조절 */
  height: auto;  /* 이미지의 비율을 유지하면서 자동으로 높이 조정 */
  display: block; 
  margin-bottom: 38px;
}

.review-base {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f3f3f3;
  background-repeat: no-repeat;
  // background-image: url('./party-review-background-web.jpg');
  // background-repeat :round;
  
}
.review-base-image {
  // max-width: 1024px;;
  // padding-top: 140px;
  //   padding-bottom: 140px;
    padding-top: 90px;
    padding-bottom: 90px;
}

@media (max-width: 991px) {
  

  .reviewCard {
    aspect-ratio: auto !important; /* 작은 화면에서는 자동으로 조정 */
  }
  .partyModalBodyWrap {
    padding: "40px" !important;
  }
  .partyModalHeaderWrap {
    padding: 24px 16px !important;
  }
  .partyModalName {
    color: rgba(255, 255, 255, 0.8) !important;
    text-align: center !important;
    font-family: "Nanum Myeongjo" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18.889px !important; /* 94.444% */
    letter-spacing: -1px !important;
    text-transform: uppercase !important;
    margin-bottom: 12px !important;
  }

  .partyModalHeader {
    color: #fff !important;
    text-align: center !important;
    font-family: Pretendard !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 8.122px !important; /* 67.685% */
    letter-spacing: 0.36px !important;
    padding: 9px !important;
  }
  .partyModalreviewerName {
    color: #dccebf !important;
    text-align: center !important;
    font-family: Pretendard !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 9.444px !important; /* 78.704% */
    letter-spacing: 0.6px !important;
    margin-bottom: 8px !important;
  }
  .partyModalDesc {
    color: #dccebf !important;
    font-family: Pretendard !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 200 !important;
    line-height: 20px !important; /* 166.667% */
    margin-top: 10px !important;
  }
  .modalStars {
    width: 8px !important;
    height: 8px !important;
  }
  .partyClose {
    width: 34px !important;
  }
  .photoReviewWrap {
    padding-top: 22px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 22px !important;
  }

  .ReviewTitle {
    color: #0b0b0b !important;
    font-family: Pretendard !important;
    font-size: 18px !important;
    font-style: normal !important;
    // font-weight: 700 !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-bottom: 22px !important;
  }

  .reviewCardHeader {
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px 16px !important;
    background-color: #4f4f4f !important;
    flex: 0 1 auto !important; /* Allow header to adjust its height */
  }

  .reviewCardHeaderName {
    padding: 0 !important;
    color: #ccc !important;
    font-family: Pretendard !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 26px !important; /* 192.308% */
    letter-spacing: -0.13px !important;
    margin-left: 0px;
  }
  
  .reviewCardHeaderStar {
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: end !important;
    margin-right: 0px;
  }
  .reviewCard {
    height: 327px !important;
  }
  
  .reviewCardBody {
    display: flex !important;
    flex: 1 1 0 !important; /* Ensure body takes the remaining space */
    align-items: end !important;
    // padding: 0px 28px 26px !important;
    padding: 0px 16px !important;
    color: #fff !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    
  }
  .reviewCardName {
    font-family: Pretendard !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 31.1px !important; /* 121.739% */
    margin-bottom: 2px !important;
    color: #fff !important;
    background: none !important;
    border: none !important;
    resize: none !important;
    white-space: pre-line
  }
  
  .reviewCardDesc {
    font-family: Pretendard !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 22.3px !important; /* 165.385% */
    letter-spacing: -0.52px !important;
    margin-bottom: 5px !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 66px; /* Adjust based on line-height */
  }
  .reviewCardMore {
    font-family: Pretendard !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22.3px !important; /* 165.385% */
    letter-spacing: -0.52px !important;
    margin-bottom: 17px !important;
  }
  .stars svg{
    width: 12.46px !important;
    height: 12.46px !important;
  }
  .review-base {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #f3f3f3;
    background-size: contain; /* 이미지가 잘리지 않고 전체 보이게 */
    // height: 100vh;
    // width: 100%;
    background-position: top; /* 모바일에서는 상단에 맞춰서 표시 */
    background-repeat: no-repeat;
    // background-image: url('./party-review-background-mo.jpg');
  }
  .review-base-image {
    max-width: 1024px;
    padding-top: 35px;
    padding-bottom: 35px;
    // background-color: #f3f3f300!important;
  }
  .review-base-image-wrraper {
    
  }
  .review-image-box img{
    width: 100%;   /* 상위 div의 너비에 맞춰서 이미지 크기 조절 */
    height: auto;  /* 이미지의 비율을 유지하면서 자동으로 높이 조정 */
    display: block; 
    margin-bottom: 12px;
  }
  .review-container {
    text-align: center !important;
    color: white;
    padding-top: 10px!important;
    padding-left: 10px!important;
    padding-right: 10px!important;
    padding-bottom: 100px!important;
    border-radius: 10px!important;
    width: 300px; /* 원하는 너비로 조정하세요 */
    margin: auto;
    
    font-family: 'Arial', sans-serif;
  }
  .review-container h1{
    color: rgba(255, 255, 255, 0.80)!important;
    text-align: center;
    font-family: 'Nanum Myeongjo';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.889px; /* 94.444% */
    letter-spacing: -1px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .review-container h2{
    font-family: Pretendard !important;
    color: #DCCEBF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    margin-bottom: 5px;
  }
  .review-stars {
    font-size: 1em;
    color: #DCCEBF;
    margin-bottom: 12px;
  }
  .review-line{
    width: 100%;
    height: 0.189px;
    background-color: #D7D6D6;
    stroke-width: 0.189px;
    stroke: #D7D6D6;
    
  }

  .review-content  {
    margin-top: 18px;
    // min-height: 412%;
  }

.review-content p {
  margin-top: 18px;
  text-align: left !important;
  color: #DCCEBF;
  font-family: Pretendard !important;
  font-size: 14px;
  line-height: 160%;
  font-style: normal;
  font-weight: 400;
  // line-height: 22px; /* 183.333% */
  letter-spacing: -0.12px;
}

.review-content p:first-child {
  margin-top: 0;
}

.review-content p:last-child {
  margin-bottom: 0;
}

}

