@import "../../assets/scss/theme.scss";

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}
.text20 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}
.text30 {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
}
.text36 {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  line-height: 1.2;
}
.text72 {
  font-size: 72px;
  color: #fff;
  font-weight: bold;
  line-height: 1.1;
}
.headerWrap {
  padding: 60px 0px !important;
}
.intsubText {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 81px;
}

.intmainText {
  color: #fff;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: 81px;
  letter-spacing: -1px;
}
.customIntButton {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.67px;
  background: #f7bdcb;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 27px 126px;
  border: none;
}
.customIntBtnWrap {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  margin-top: 30px !important;
}

.mainImg {
  src: "../../assets/img/others/landing.jpg";
}
.purMainTitle2 {
  color: #0b0b0b !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-top: 50px !important;
  margin-bottom: 20px !important;
  text-align: center;
}
.purSubTitle2 {
  color: #0b0b0b !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-bottom: 18px !important;
  text-align: center;
}
.popupImage{
  // height: 90%!important;
  // width: auto;
  // width: 100%;
}
.amodal-header{
  color: #687889;
  flex-shrink: 0;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 1.56rem; /* 156.667% */
margin-top: 62px;
margin-bottom: 30px;
margin-left: 64px;
margin-right: 64px;
max-width: 710px;
word-break: keep-all;
}
.amodal-close-part {
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 27px;
  padding-bottom: 27px;
  width: 100%;
  
  border-radius: 0px 0px  16px 16px;
  background: #71869D;
  color: #FFF;

  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: space-between;
  align-items: center; /* 세로 정렬도 필요하다면 사용 */
}
.amodal .white {
  background-color: white;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}



@media (max-width: 991px) {
  .popupImage{
    width: 100%;
    height: auto;
  }
  .text20 {
    font-size: 15px !important;
  }
  .text30 {
    font-size: 20px !important;
  }
  .text36 {
    font-size: 24px !important;
  }
  .text72 {
    font-size: 36px !important;
  }
  .headerWrap {
    padding: 0px !important;
  }

  .purMainTitle2 {
    color: #0b0b0b !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    margin-top: 50px !important;
    margin-bottom: 20px !important;
    text-align: center;
  }
  .purSubTitle2 {
    color: #0b0b0b !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-bottom: 18px !important;
    text-align: center;
  }
}

.mt30 {
  margin-top: 30px !important;
}
.ceo-web-text {
  // word-break: keep-all !important;
  margin-bottom: 64px;
  color: #4f4f4f;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px; /* 209.091% */
}

.ceo-web-text-border {
  // word-break: keep-all !important;
  color: #333;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 168.75% */
  margin-top: 16px;
  margin-bottom: 80px;
}
.justifyContentCenter {
  justify-content: center;
}
.maxWidth600{
  max-width: 600px!important;
}
.ceo-text {
  // word-break: keep-all !important;
  margin-bottom: 28px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px; /* 235.714% */
}

.ceo-text-border {
  // word-break: keep-all !important;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px; /* 235.714% */
  margin-bottom: 28px;
}


.amodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.amodal-content {
  position: relative;
  max-width: 90%;
  max-height: 100%;
}


.amodal-content img {
  max-width: 100%;
  max-height: 100%;
  
  height: 100vh;
}

.amodal-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.adont-show {
  color: white;
  font-size: 16px;
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;  
}

.adont-show input {
  margin-right: 10px;
}

.aclose-button {
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;  
}
.aclose-button-none {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  //오른쪽 정렬
  margin-left: auto;
  margin-right: 30px;
}
#modalOneHour{
  transform: scale(2);
  
}
.checklabel{
  margin-left: 20px;
}


/* Mobile styles */
@media (max-width: 768px) {
  .amodal-content {
    max-width: 100%;
    max-height: 100%;
    margin: 0 10px;
  }

  .amodal-footer {
    // flex-direction: column;
    align-items: flex-start;
    bottom: 0px;
  }

  .adont-show {
    margin-bottom: 0px;
  }

  .aclose-button {
    // align-self: flex-end;
  }
  .amodal-content img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: 100%;
  }


  .amodal-header{
    line-height: 1.5rem;
  font-size: 1rem;
  // line-height: 47px; /* 156.667% */
  margin-top: 1rem;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 0px;
  // max-width: 947px;
  }
  .amodal-close-part {
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 17px;
    padding-bottom: 17px;
    width: 100%;
    
    // border-radius: 0px 0px  16px 16px;
    // background: #71869D;
    // color: #FFF;
  
    // font-family: Pretendard;
    font-size: 1rem;
    line-height: 1rem;
  }
  .aclose-button-none {
    font-size: 1rem;
    line-height: 1rem;
  }
  #modalOneHour{
    transform: scale(2);
  }
}