.couple-detail-background {
    font-family: 'NanumSquareAc', sans-serif;
    background-color: #DBD1C5;
    margin: 0;
    padding: 0px;
    padding-bottom: 50px;
    z-index: 100;
}

.couple-detail-conversation {
    z-index: 100;
    max-width: 1440px;
    margin-top: 197px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
/*            background-color: #ece7e1;*/
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(20px);
    padding-top: 100px;
    padding-left: 78px;
    padding-right: 78px;
    padding-bottom: 100px;
    border-radius: 50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.couple-detail-question {
    font-family: 'NanumMyeongjo', sans-serif;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 13px;
    margin-left: 177px;
    margin-right: 177px;
    color: #333333;
    
}
.couple-detail-question:not(:first-child) {
    margin-top: 100px;
}

.couple-detail-answer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 11px;
    position: relative;
}

.couple-detail-answer.male {
    justify-content: flex-start;
}

.couple-detail-answer.female {
    justify-content: flex-end;
}

.couple-detail-answer img {
    width: 132px;
    height: 132px;
/*            margin: 0 10px;*/
}

.couple-detail-answer.male img {
    order: 1;
    margin-right: 40px;
    margin-left: 0;
}

.couple-detail-answer.female img {
    order: 2;
    margin-left: 40px;
    margin-right: 0;
}

.couple-detail-answer-text {
    margin-top: 17px;
    background-color: #f2f2f2;
    padding: 25px 25px;
    border-radius: 20px;
    font-size: 26px;
    line-height: 43px;
    width:100%;
/*            max-width: 64%;*/
    position: relative;
    white-space: pre-line
}

.couple-detail-answer.male .couple-detail-answer-text {
    order: 2;
    background-color: #FFFFFF;
    color:#4F4F4F;
    margin-right: 171px;
}

.couple-detail-answer.female .couple-detail-answer-text {
    order: 1;
    background-color: #797367;
    color:#ffffff;
    margin-left: 171px;
}

.couple-detail-answer.male .couple-detail-answer-text::before {
    content: "";
    position: absolute;
    top: 25px;
    left: -25px;
    width: 30px;
    height: 30px;
    background: url('1.png') no-repeat;
    background-size: cover;;
}

.couple-detail-answer.female .couple-detail-answer-text::before {
    content: "";
    position: absolute;
    top: 25px;
    right: -25px;
    width: 30px;
    height: 30px;
    background: url('2.png') no-repeat;
    background-size: cover;
}
.couple-detail-banner {
    background-color: #DBD1C5;
    width: 100%;
    height: 770px;
    object-fit: cover;
    /* object-position: center top; */
    /* position: absolute;
    z-index: -1; */
}
.couple-detail-title {

    font-family: 'NanumMyeongjo', sans-serif;
    text-align: center;
    font-size: 110px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.1px;
    text-transform: uppercase;
/*			color: rgba(255, 255, 255, 0.80);*/
    color: white;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
    margin-bottom: 0;
    text-shadow: 1px 1px 2px black;
}
.couple-detail-title-wrapper {
    padding-top: 0px;
    z-index: 100;
    margin-top: -480px;
}
.couple-detail-sub_title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.348px; /* 104.492% */
    margin-top: 23px;
    text-shadow: 1px 1px 1px black;
}
.couple-detail-top-banner {
    
    width: 100%;
    height: 770px;
    position: absolute;
    /* z-index: 1; */
}
@media (max-width: 991px) {
    .couple-detail-background {
        font-family: 'NanumSquareAc', sans-serif;
        background-color: #DBD1C5;
        margin: 0;
        padding: 0px;
        padding-bottom: 50px;
        z-index: 100;
    }
    
    .couple-detail-conversation {
        z-index: 100;
        width: 90%;
        margin-top: 35px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    /*            background-color: #ece7e1;*/
        background: rgba(255, 255, 255, 0.50);
        backdrop-filter: blur(20px);
        padding-top: 40.76px;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 40.76px;
        border-radius: 12px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    
    .couple-detail-question {
        font-family: 'NanumMyeongjo', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 120% */
        letter-spacing: -0.15px;
        margin-bottom: 10px;
        margin-left: 40px;
        margin-right: 40px;
        color: #333333;
        
    }
    .couple-detail-question:not(:first-child) {
        margin-top: 36px;
    }
    
    .couple-detail-answer {
        display: flex;
        align-items: flex-start;
        margin-bottom: 11px;
        position: relative;
    }
    
    .couple-detail-answer.male {
        justify-content: flex-start;
    }
    
    .couple-detail-answer.female {
        justify-content: flex-end;
    }
    
    .couple-detail-answer img {
        width: 28px;
        height: 28px;
    /*            margin: 0 10px;*/
    }
    
    .couple-detail-answer.male img {
        order: 1;
        margin-right: 12px;
        margin-left: 0;
    }
    
    .couple-detail-answer.female img {
        order: 2;
        margin-left: 12px;
        margin-right: 0;
    }
    
    .couple-detail-answer-text {
        margin-top: 0px;
        background-color: #f2f2f2;
        padding: 8px 10px;
        border-radius: 6px;
        font-size: 12px;
        line-height: 18px;
        width:100%;
    /*            max-width: 64%;*/
        position: relative;
        white-space: pre-line
    }
    
    .couple-detail-answer.male .couple-detail-answer-text {
        order: 2;
        background-color: #FFFFFF;
        color:#4F4F4F;
        margin-right: 40px;
    }
    
    .couple-detail-answer.female .couple-detail-answer-text {
        order: 1;
        background-color: #797367;
        color:#ffffff;
        margin-left: 40px;
    }
    
    .couple-detail-answer.male .couple-detail-answer-text::before {
        content: "";
        position: absolute;
        top: 12px;
        left: -8px;
        width: 10px;
        height: 10px;
        background: url('1.png') no-repeat;
        background-size: cover;;
    }
    
    .couple-detail-answer.female .couple-detail-answer-text::before {
        content: "";
        position: absolute;
        top: 12px;
        right: -9px;
        width: 10px;
        height: 10px;
        background: url('2.png') no-repeat;
        background-size: cover;
    }
    .couple-detail-banner {
        background-color: #DBD1C5;
        width: 100%;
        height: 160px;
        object-fit: cover;
        /* position: absolute;
        z-index: -1; */
    }
    .couple-detail-title {
    
        font-family: 'NanumMyeongjo', sans-serif;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.1px;
        text-transform: uppercase;
    /*			color: rgba(255, 255, 255, 0.80);*/
        color: white;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0px;
        margin-bottom: 0;
        text-shadow: 1px 1px 2px black;
    }
    .couple-detail-title-wrapper {
        padding-top: 0px;
        z-index: 100;
        margin-top: -111px;
    }
    .couple-detail-sub_title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 5.859px; /* 104.492% */
        margin-top: 6px;
        text-shadow: 1px 1px 1px black;
    }
    .couple-detail-top-banner {
        
        width: 100%;
        height: 160px;
        position: absolute;
        /* z-index: 1; */
    }
}