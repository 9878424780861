
.purTitle {
  color: #333 !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-bottom: 20px !important;
}

.purTableHeader {
  border-top: 1px solid #d6d7d7 !important;
  background: #fbfbfb !important;
  padding: 9px !important;
  color: #b7b7b7 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 37px !important;
}

.purTableBodyTr {
  border-bottom: 1px solid #d6d7d7 !important;
  background: #fff !important;
  padding: 35px 30px !important;
  color: #333 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 37px !important;
  letter-spacing: -0.54px !important;
}

.purRadio {
  width: 24px !important;
  height: 24px !important;
  margin: 30px !important;
}

.purfooter {
  color: #333 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.48px !important;
  margin-top: 26px !important;
}

.furRight {
  border-left: 1px solid #d6d7d7 !important;
  height: 100% !important;
  padding: 40px 30px !important;
  position: relative !important;
}

.furRightInWrap {
  position: sticky !important;
  top: 140px !important;
}

.furRightMain {
  margin-bottom: 30px !important;
}

.furRightMainTitle {
  color: #333 !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 37px !important;
  letter-spacing: -0.34px !important;
}

.furRightVAT {
  color: #333 !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 37px !important;
  letter-spacing: -0.26px !important;
}

.furNameText {
  color: #333 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 37px !important;
}

.furRightPrice {
  color: #333 !important;
  text-align: right !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 37px !important;
}

.purcollapWrap {
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.purcollap {
  //   border-radius: 8px;
  padding: 8px 16px !important;
  background: #fbfbfb !important;
  color: #b7b7b7 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 37px !important;
  letter-spacing: -0.28px !important;
}

.purAgree {
  color: #333 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 37px !important;
  margin-bottom: 10px !important;
}

.purBtn {
  border-radius: 8.709px !important;
  border: 1px solid #e5e7ea !important;
  background: #fff !important;
  color: #000 !important;
  text-align: center !important;
  font-size: 17.419px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: normal !important;
  padding: 12px !important;
  margin-bottom: 10px;
}

.purkakaoBtn {
  border-radius: 8.709px !important;
  border: 1px solid #e5e7ea !important;
  background: #fff !important;
  color: #9aa3af !important;
  text-align: center !important;
  font-size: 17.419px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: normal !important;
  padding: 20px !important;
}

.purcard {
  padding: 0 !important;
  border: 1px solid #fbfbfb !important;
}

.purchase-container {
  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column !important;

    .furRightWrap {
      order: 2;
    }

    .purfooterWrap {
      order: 1; /* furRight와 purfooter의 순서를 바꾸기 위해 purfooter에 더 낮은 순서 값을 줍니다. */
    }
  }
}

.purCustomTextarea {
  resize: none !important;
  width: 100% !important;
  border: 1px solid #d6d7d7 !important;
  background: #fbfbfb !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  margin-bottom: 29px !important;
}

.purLabelWrap {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 64px !important;
}

.purCheckbox {
  width: 20px !important;
  height: 20px !important;
  flex-shrink: 0 !important;
  border: 1px solid #6e737c !important;
  margin-right: 10px !important;
}

.purLabel {
  color: #333 !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
}

.purCustomTable {
  border-top: 1px solid #d6d7d7 !important;
  width: 100% !important;
}

.purCustomTableTr {
  border-bottom: 1px solid #d6d7d7 !important;
}

.purCustomTableTdName {
  background: #fbfbfb !important;
  text-align: center !important;
  width: 139px !important;
  color: #333 !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 37px !important;
  letter-spacing: -0.48px !important;
  padding: 20px !important;
}

.purCustomTableTdInputWrap {
  padding: 22px 18px !important;
}

.purCustomTableTdInput {
  border: 1px solid var(--border-border-secondary, #d6d7d7);
  height: 35px !important;
  padding: 0px 12px;
}
.purCustomTableTdInputEmail {
  border: 1px solid var(--border-border-secondary, #d6d7d7);
  height: 35px !important;
  padding: 0px 12px;
}
.purCustomTableTdSelect {
  border: 1px solid var(--border-border-secondary, #d6d7d7);
  height: 35px !important;
  background-color: #fff !important;
  margin-top: 10px !important;
  width: 100%;
  padding: 0px 12px;
}
.purCustomTableTdInput2 {
  margin: 0px 10px !important;
}

@media (max-width: 991px) {
  .purTitle {
    color: #0b0b0b !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 20px !important;
  }

  .purTableHeader {
    border-top: 1px solid #d6d7d7 !important;
    background: #fbfbfb !important;
    padding: 9px !important;
    color: #b7b7b7 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 37px !important;
  }

  .purTableBodyTr {
    border-bottom: 1px solid #d6d7d7 !important;
    background: #fff !important;
    padding: 35px 30px !important;
    color: #333 !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    letter-spacing: -0.54px !important;
  }

  .purRadio {
    width: 24px !important;
    height: 24px !important;
    margin: 40px 20px 40px 16px !important;
  }

  .purfooter {
    color: #333 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: -0.39px !important;
    margin-top: 20px !important;
    margin-bottom: 46px !important;
  }

  .furRight {
    border-top: 1px solid #d6d7d7 !important;
    border-left: none !important;
    height: 100% !important;
    padding: 20px 0px !important;
  }

  .furRightMain {
    margin-bottom: 30px !important;
  }

  .furRightMainTitle {
    color: #333 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 37px !important;
    letter-spacing: -0.32px !important;
  }

  .furRightVAT {
    color: #333 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 37px !important;
    letter-spacing: -0.26px !important;
  }
  .furNameText {
    color: #333 !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 37px !important;
  }
  .furRightPrice {
    color: #333 !important;
    text-align: right !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 37px !important;
  }

  .purcollapWrap {
    margin-bottom: 10px !important;
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  .purcollap {
    //   border-radius: 8px;
    padding: 8px 16px !important;
    background: #fbfbfb !important;
    color: #b7b7b7 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 37px !important;
    letter-spacing: -0.28px !important;
  }

  .purAgree {
    color: #333 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 37px !important;
    margin-bottom: 12px !important;
  }

  .purBtn {
    border-radius: 8.709px !important;
    border: 1px solid #e5e7ea !important;
    background: #fff !important;
    color: #000 !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
    padding: 12px !important;
    margin-bottom: 10px;
  }

  .purkakaoBtn {
    border-radius: 8.709px !important;
    border: 1px solid #fae300 !important;
    background: #fae300 !important;
    color: #391b1b !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
    padding: 18px !important;
  }

  .purcard {
    padding: 0 !important;
    border: 1px solid #fbfbfb !important;
  }

  .purCustomTextarea {
    margin-bottom: 10px !important;
  }

  .purLabelWrap {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 40px !important;
  }

  .purCheckbox {
    width: 16px !important;
    height: 16px !important;
    flex-shrink: 0 !important;
    border: 1px solid #6e737c !important;
    margin-right: 10px !important;
  }

  .purLabel {
    color: #333 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }

  .purCustomTable {
    border-top: 1px solid #d6d7d7 !important;
    width: 100% !important;
    margin-top: 15px;
  }

  .purCustomTableTdName {
    display: none !important;
  }
  .purCustomTableTdInputWrap {
    padding: 22px 0px !important;
  }
}
