.titleName {
  color: #0b0b0b !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-bottom: 50px !important;
}

.subtitleName {
  color: #333 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  letter-spacing: -0.54px !important;
}

.productscardName {
  color: #0b0b0b !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 22px !important;
  margin-bottom: 31px !important;
}

.productsCustomCard {
  width: 100% !important;
  border-radius: 36px !important;
  background: #fff !important;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 56px 60px !important;
  display: flex !important ;
  flex-direction: row !important;
  margin-bottom: 46px;
}

.productsCustomCardInName {
  color: #4abaf2 !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 46px !important;
}

.productsCustomCardInPrice {
  color: #0b0b0b !important;
  font-size: 72px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.productsCustomCardInSubPrice {
  color: #0b0b0b !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.productsCustomCardInSubSubPrice {
  color: #0b0b0b !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 11px !important;
}

.productsCustomCardInVAT {
  color: #0b0b0b !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-top: 16px !important;
  margin-bottom: 50px !important;
}

.marryfeeyMarginBottom {
  margin-bottom: 130px !important;
}

.svgs {
  width: 182.652px !important;
  height: 180px !important;
}
.svgs1 {
  width: 199.277px !important;
  height: 160px !important;
}

@media (max-width: 991px) {
  .titleName {
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.54px !important;
    margin-bottom: 8px !important;
  }

  .subtitleName {
    color: #333 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: -0.39px !important;
  }

  .productscardName {
    color: #0b0b0b !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-top: 12px !important;
    margin-bottom: 18px !important;
  }

  .productsCustomCard {
    width: 100% !important;
    border-radius: 36px !important;
    background: #fff !important;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12) !important;
    padding: 30px 30px !important;
    display: flex !important ;
    flex-direction: row !important;
    margin-bottom: 24px;
  }

  .productsCustomCardInName {
    color: #4abaf2 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 24px !important;
  }

  .productsCustomCardInPrice {
    color: #0b0b0b !important;
    text-align: right !important;
    font-size: 38px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }

  .productsCustomCardInSubPrice {
    color: #0b0b0b !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .productsCustomCardInSubSubPrice {
    color: #0b0b0b !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 6px !important;
  }

  .productsCustomCardInVAT {
    color: #0b0b0b !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-top: 8px !important;
    margin-bottom: 29px !important;
  }

  .marryfeeyMarginBottom {
    margin-bottom: 49px !important;
  }

  .svgs {
    width: 96.891px !important;
    height: 95.484px !important;
  }
  .svgs1 {
    width: 104.615px !important;
    height: 83.996px !important;
  }
}
