.MainText {
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mainBottomMargin {
  margin-bottom: 77px !important;
}

.SubText {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.48px;
}
.SubText {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.48px;
}
.sub3Text {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.48px;
}
.mottomMargin45 {
  margin-bottom: 45px !important;
}
.subBottomMargin18 {
  margin-bottom: 18px !important;
}
.etcText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.16px;
}

.labelText {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 6px;
}

.checkLabelText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.customInput {
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
  transition: none !important;
  //   --bs-form-select-bg-img: url(../../assets/icon/svgs/solid/chevron-down.svg) !important;
}

.customInputLeft {
  border-top: 0.5px solid #6e737c !important;
  border-bottom: 0.5px solid #6e737c !important;
  border-left: 0.5px solid #6e737c !important;
  border-right: none !important;
  border-radius: 0 !important;
  transition: none !important;
}

.customInputRight {
  border-top: 0.5px solid #6e737c !important;
  border-bottom: 0.5px solid #6e737c !important;
  border-right: 0.5px solid #6e737c !important;
  border-left: none !important;
  border-radius: 0 !important;
  transition: none !important;
  //   --bs-form-select-bg-img: url(../../assets/icon/svgs/solid/chevron-down.svg) !important;
}

.customCheckBox {
  width: 21px !important;
  height: 21px !important;
  flex-shrink: 0 !important;
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
}

.customButton {
  border: none;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 27px 0px;
  border-radius: 0;
}

.customInput::placeholder {
  color: #bcbcbc !important;
}

.topPadding {
  padding-top: 85px !important;
  padding-bottom: 5rem !important;
}

.hopeMargin {
  margin-bottom: 80px !important;
}

.checkMarginTop {
  margin-top: 0px !important;
}
.checkMarginBottom {
  margin-top: 30px !important;
}

.customBtnWrap {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 30px !important;
}

.brcontroll {
  display: none;
}

.modalWrap {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modalCard {
  white-space: pre-wrap;
  overflow: auto;
}

.busymodalCard {
  white-space: pre-wrap !important;
  overflow: auto !important;
  height: 100% !important;
}

.customCard {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 37px 24px;
}

.customCardText {
  color: #333;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}

.customCardInput {
  border-radius: 4px;
  border: 0.75px solid #333;
}

.introProfile {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.144px;
}

.introCustomButtonWrap {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.introCustomButton {
  border: none;
  background: linear-gradient(180deg, #cce6fe 0%, #7fafea 81.48%);
  // background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}
.introCustomPrimiumButton {
  border: none;
  background: linear-gradient(90deg, #7fafea 0%, #bccbfe 100%);
  box-shadow: 0px 4px 9px 0px rgba(99, 140, 203, 0.2);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 16px 0px;
  border-radius: 8px;
  width: 100%;
}
.introCustomRejectButton {
  border: 1px solid #ccc;
  background: #fff;
  // background-color: #333;
  color: #333333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 16px 0px;
  border-radius: 30px;
  width: 100%;
}

.introCustomNoButton {
  border: none;
  background: linear-gradient(180deg, #fecccc 0%, #ea7f7f 81.48%);
  // background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 20px 0px;
  border-radius: 30px;
  width: 100%;
}

.customMainText {
  color: #333;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.75px;
  margin-bottom: 40px;
}
#customimage {
  display: none;
}
.introMainText {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.144px;
  margin-top: 40px;
  word-break: keep-all;
}
.introNameText {
  text-align: center;
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 12px 0px 21px;
}
.introsubext {
  text-align: center;
  color: #818181;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.144px;
  margin-bottom: 28px;
  word-break: keep-all;
}
.introlabelText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.144px;
  margin-bottom: 10px;
}
.introlabelInput {
  // border-radius: 6px;
  // border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.144px; /* 119.65% */
  padding: 14px;
  display: block;
  width: 100%;
  border: none;
}
.introlabelInput:focus {
  outline: none !important;
}
.mottomMargin30 {
  margin-bottom: 30px !important;
}

.busycardTextWrap {
  overflow: auto !important;
  padding: 60px 64px 60px 64px !important;
  height: 100% !important;
}

.busycardText {
  height: 100% !important;
  border-radius: 8px !important;
  color: #687889 !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 47px !important;
}

.customTalkContainer {
  background: #f9f9f9;
}
.request-kakaotalk-id{
  
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 12px;
}

@media (max-width: 991px) {
  .request-kakaotalk-id{
  
    color: #333;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.75px; /* 131.25% */
    letter-spacing: -0.32px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 12px;
  }
  .MainText {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mainBottomMargin {
    margin-bottom: 20px !important;
  }

  .SubText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  .etcText {
    color: #333;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.98px;
    letter-spacing: 0.22px;
    display: flex;
    justify-content: end;
  }

  .labelText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.144px;
  }

  .checkLabelText {
    color: #333;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.223px;
    letter-spacing: -0.4px;
  }

  .customButton {
    border: none;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 17px 88px;
    border-radius: 0;
  }
  .topPadding {
    padding-top: 22px !important;
  }

  .mottomMargin45 {
    margin-bottom: 25px !important;
  }
  .subBottomMargin18 {
    margin-bottom: 8px !important;
  }
  .hopeMargin {
    margin-bottom: 17px !important;
  }
  .checkMarginTop {
    margin-top: 30px !important;
  }
  .checkMarginBottom {
    margin-top: 15px !important;
  }
  .customBtnWrap {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 8px !important;
  }
  .brcontroll {
    display: block;
  }
  .busymodalCard {
    white-space: pre-wrap !important;
    overflow: auto !important;
    height: auto !important;
  }
  .busycardTextWrap {
    overflow: auto !important;
    padding: 20px !important;
    height: 200px !important;
  }

  .busycardText {
    height: 100% !important;
    border-radius: 8px !important;
    color: #687889 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: -0.14px !important;
  }
  .busyModalText {
    color: #687889;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.14px;
  }
}

.image-container {
  position: relative;
  display: inline-block;
  cursor: zoom-in;
}

.image-container img {
  width: 300px;
  transition: transform 0.5s ease-in-out;
}

.image-container img.zoomed {
  transform: scale(2);
  cursor: zoom-out;
}

.close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: none;
}

.close-button.show {
  display: block;
}

.profile-container {
  display: flex;
  align-items: center;
}

.profile-title {
  color: #333;
  font-family: "Noto Serif HK";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.144px;
}

.profile-underline {
  flex-grow: 1;
  height: 1px; /* 밑줄의 두께 조정 */
  background-color: lightgray; /* 밑줄 색상 조정 */
  border: none;
  margin-left: 8px; /* 텍스트와 밑줄 사이의 간격 조정 */
}

.productsInfoBtn {
  border-radius: 10.5px;
  border: 1px solid #7fafea;
  padding: 3px 3px 2px 6px;
  overflow: hidden;
  color: #7fafea;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.productsInfoButtonWrap {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}

.sliderCustomRadius {
  border-radius: 6px !important;
  overflow: hidden !important;
}

.introCustomPostButton {
  border: 1px solid #7fafea;
  background: none;
  box-shadow: 0px 4px 9px 0px rgba(99, 140, 203, 0.2);
  color: #7fafea;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 16px 0px;
  border-radius: 8px;
  width: 100%;
}

.introCustomRejectPostButton {
  border: none;
  background: none;
  // background-color: #333;
  color: #818181;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  border-radius: 30px;
  margin-top: 10px;
}

.primiumMainText {
  margin-top: 0 !important;
  color: #333;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  word-break: keep-all;
  margin-bottom: 10px;
}

.primiumSubText {
  color: #818181;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.15px;
  word-break: keep-all;
  margin-bottom: 24px;
}

.primiumMsgText {
  color: #4f4f4f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}

#primiumRejectBtn {
  padding: 20px 0px !important;
  border: none;
  border-radius: 8px !important;
  background: #f3f3f3 !important;
  gap: 10px !important;
  color: #4f4f4f !important;
  font-family: "Pretendard Variable" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  width: 100% !important;
}

#primiumAccesBtn {
  padding: 20px 0px !important;
  border: none;
  border-radius: 8px !important;
  background: #4f4f4f !important;
  gap: 10px !important;
  color: #f5f5f5 !important;
  font-family: "Pretendard Variable" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  width: 100% !important;
}

.custommodalWrap {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2000 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  overflow: auto !important;
}

.infoLeftText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.16px;
  margin-bottom: 9px;
}

.infoRightText {
  border-radius: 4px;
  background: #9cc6f9;
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.12px;
  margin-bottom: 9px;
  width: 106px;
}

.infoBorder {
  margin: 10px 0px;
  width: 100%;
  background-color: #eee;
  height: 1px;
}

.infoDetailText {
  color: #4f4f4f;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.36px;
  word-break: keep-all;
  margin-top: 4px;
  margin-bottom: 16px;
}

#sliderTotalWrap {
  padding: 10px !important;
}

#sliderWrap {
  background: #d0ccc8 !important;
  padding: 12px !important;
  border-radius: 0 !important;
}

.sliderInWrap {
  background: #f8f8f8 !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.34) !important;
  padding: 40px 40px 22px 40px !important;
  border-radius: 0 !important;
}

.sliderInCard {
  border-radius: 12px !important;
  background: #fff !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 24px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.sliderProfileimg {
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  overflow: hidden !important;
  margin-bottom: 10px !important;
}

.sliderimgWrap {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
}

.sliderName {
  color: #333;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.sliderName {
  color: #333;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 8px !important;
}

.sliderBirthday {
  color: #818181;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px !important;
}

.sliderCardBtn {
  border-radius: 6px !important;
  border: 1px solid #ccc !important;
  color: #333 !important;
  background: none !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  padding: 20px 0px !important;
  width: 100% !important;
}

#sliderImgs {
  padding: 0px 10px;
}

.noneBtn {
  background: none;
  border: none;
  padding: 0;
}

.snapdiffentment {
  color: #818181;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.144px;
  margin: 8px 0px 20px 0px;
  word-break: keep-all;
}

.ddaycard {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 12.5px;
  background: #dfedff;
  color: #5599ec;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.144px;
  padding: 3px 8px !important;
  margin: 0px 12px 12px 0px !important;
}

.capSvgWrap {
  background-color: (180deg, #bccbfe 0%, #7fafea 100%);
}

.custom-select-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* 원하는 너비로 조정 */
}

.custom-select {
  width: 100%;
  padding: 10px 40px 10px 10px;
  font-size: 16px;
  appearance: none;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%204%205%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M0%200l2%202l2-2z%22/%3E%3C/svg%3E")
    no-repeat right 20px center;
  background-size: 10px;
  background-position: right 10px center;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.customselect1:focus {
  outline: none;
  border-color: none; /* 포커스 시 테두리 색상 */
}
.talkcard {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
}

.talkheader {
  display: flex;
  align-items: center;
}

.talkicon {
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 50%;
  margin-right: 12px;
}

.talkheader h3 {
  margin: 0;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.144px;
}

.talkcontent {
  margin-top: 10px;
}

.talkcharacter-textarea {
  width: 100%;
  border: none;
  resize: none;
  overflow: hidden;
  background: none;
  padding: 0;
  color: #4f4f4f;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.14px;
  padding-left: 42px;
}

.talkcharacter-textarea:focus {
  outline: none;
}
