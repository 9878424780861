.purMainTitle {
  color: #0b0b0b !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-bottom: 100px !important;
}

.purSubTitle {
  color: #0b0b0b !important;
  font-family: Pretendard !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.48px !important;
  margin-bottom: 24px !important;
}

.purCard {
  box-shadow: none !important;
  border-radius: 14px !important;
  border: 1px solid #cecece !important;
  background: #fff !important;
  padding: 40px 0px 58px;
  margin-bottom: 24px !important;
  text-align: center;
}

.purCardItemType {
  color: #cecece !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  margin-bottom: 33px !important;
}

.purCardItemName {
  color: #0b0b0b !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.3px !important;
  margin-bottom: 16px !important;
}

.purCardItemName27 {
  color: #0b0b0b !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 27px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.3px !important;
  margin-bottom: 16px !important;
}

.purCardItemPrice {
  color: #0b0b0b !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 45px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 1.35px !important;
  margin-bottom: 8px !important;
}

.purCardItemVAT {
  color: #0b0b0b !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important; /* 144.444% */
  letter-spacing: -0.18px !important;
  margin-bottom: 33px !important;
}

.purCardItemDesc {
  color: #35ade2 !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 107.692% */
  letter-spacing: 0.26px !important;
  white-space: pre-wrap;
}
.purCardItemDescSubscribe {
  color: #35ade2 !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 26px !important;
  font-style: normal !important;
  // font-weight: 700 !important;
  line-height: 28px !important; /* 107.692% */
  letter-spacing: 0.26px !important;
  white-space: pre-wrap;
}
.purCardItemDescNormal {
  color: #0B0B0B !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 21px !important;
  font-style: normal !important;
  // font-weight: 700 !important;
  line-height: 28px !important; /* 107.692% */
  letter-spacing: 0.26px !important;
  white-space: pre-wrap;
}


.purCardItemBtn {
  width: 100% !important;
  border-radius: 6px !important;
  border: 1px solid #35ade2 !important;
  background: #35ade2 !important;
  margin: 0 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding: 15px 0px !important;
}

.purRow {
  margin-bottom: 76px !important;
}

.mt131 {
  margin-top: 131px !important;
}
@media (max-width: 991px) {
  .purMainTitle {
    color: #0b0b0b !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.54px !important;
    margin-bottom: 23px !important;
  }

  .purSubTitle {
    color: #0b0b0b !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-bottom: 18px !important;
  }

  .purCardItemName {
    color: #000 !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 9px !important;
  }

  .purCardItemPrice {
    color: #000 !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 0.72px !important;
    margin-bottom: 12px !important;
  }

  .purCardItemVAT {
    color: #000 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .purCardItemDesc {
    color: #35ade2 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 14.84px !important;
    letter-spacing: -0.14px !important;
    white-space: pre-wrap;
  }
  .purCardItemDescSubscribe {
    color: #35ade2 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 14.84px !important;
    letter-spacing: -0.14px !important;
    white-space: pre-wrap;
  }
 
  .purCardItemDescNormal {
    color: #0B0B0B !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 14.84px !important;
    letter-spacing: -0.14px !important;
    white-space: pre-wrap;
  }
  

  .purCardItemBtn {
    width: 100% !important;
    border-radius: 6px !important;
    border: 1px solid #35ade2 !important;
    background: #35ade2 !important;
    margin: 0 !important;
    color: #fff !important;
    text-align: center !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    padding: 8px 0px !important;
  }

  .purRow {
    margin-bottom: 36px !important;
  }

  .mt131 {
    margin-top: 30px !important;
  }
}
